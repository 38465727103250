//Project Images
import inflection1 from './images/portfolio/Inflection/Inflection-brands-1.png';
import inflection2 from './images/portfolio/Inflection/Inflection-brands-2.png';
import inflection3 from './images/portfolio/Inflection/Inflection-brands-3.png';
import pricerbot1 from './images/portfolio/Pricerbot/Pricerbot-1.png';
import pricerbot2 from './images/portfolio/Pricerbot/Pricerbot-2.png';
import pricerbot3 from './images/portfolio/Pricerbot/Pricerbot-3.png';
import pricerbot4 from './images/portfolio/Pricerbot/Pricerbot-4.png';
import bwd1 from './images/portfolio/BWD/BWD1.jpg'
import bwd2 from './images/portfolio/BWD/BWD2.jpg'
import bwd3 from './images/portfolio/BWD/BWD3.jpg'
import bwd4 from './images/portfolio/BWD/BWD4.jpg'
import bwd5 from './images/portfolio/BWD/BWD5.jpg'
import gab1 from './images/portfolio/giftabliss/gab1.png';
import gab2 from './images/portfolio/giftabliss/gab2.png';
import gab3 from './images/portfolio/giftabliss/gab3.png';
import gab4 from './images/portfolio/giftabliss/gab4.png';
import gab5 from './images/portfolio/giftabliss/gab5.png';
import gab6 from './images/portfolio/giftabliss/gab6.png';
import mae1 from './images/portfolio/mae/mae1.png';
import mae2 from './images/portfolio/mae/mae2.png';
import mae3 from './images/portfolio/mae/mae3.png';
import mae4 from './images/portfolio/mae/mae4.png';
import zap1 from './images/portfolio/zap/zap1.png';
import zap2 from './images/portfolio/zap/zap2.png';
import zap3 from './images/portfolio/zap/zap3.png';
import zap4 from './images/portfolio/zap/zap4.png';
import gv1 from './images/portfolio/guardianvets/gv1.png';
import gv2 from './images/portfolio/guardianvets/gv2.png';
import yjsj1 from './images/portfolio/yjsj/image1.png';
import yjsj2 from './images/portfolio/yjsj/image2.png';
import yjsj3 from './images/portfolio/yjsj/image3.png';
import yjsj4 from './images/portfolio/yjsj/image4.png';
import yjsj5 from './images/portfolio/yjsj/image5.png';
import yjsj6 from './images/portfolio/yjsj/image6.png';
import yjsj7 from './images/portfolio/yjsj/image7.png';
import yjsj8 from './images/portfolio/yjsj/image8.png';
const projects = [
  {
    "title": "YJSJ",
    "startDate": "2023",
    "description": "Job search genius is a AI powered platform for job searchers. It allows you to create ATS resumes, track jobs, generate cover letters, and generate offer negotiation points, all with the power of AI at the back. It is a fully white label solution. It has microservices based architecture. In the admin side of this platform. The admin can add staff members, assign permissions, add users and view user data. Since its fully white labeled, the customer view the website on admins domain and receive emails from the admins domain as well. Similarly, the staff also has full access of user functionality, if it has the right permissions. The super-admin can add companies, activate and deactivate companies and view key performance indicators in the form of charts.", 
    "role":"In the project, I served as the lead developer responsible for designing and implementing the entire administrative functionality of the Job Search Genius platform. Leveraging my expertise in microservices-based architecture, I crafted features enabling administrators to seamlessly manage staff members, allocate permissions, add users, and access user data. Additionally, I ensured the platform's full white-label capabilities, allowing customers to interact with the website and receive emails under the administrator's domain. My role extended to providing staff members with comprehensive access to user functionalities based on their assigned permissions. As a key contributor, I played a pivotal role in enhancing the overall functionality and user experience of the admin app, contributing significantly to the success of the project.",
    "images": [
      yjsj1,
      yjsj2,
      yjsj3,
      yjsj4,
      yjsj5,
      yjsj6,
      yjsj7,
      yjsj8,
    ],
    "url": "https://app.jobsearchgenius.ai/login",
    "technologies": [
      {
        "class": "devicon-react-original",
        "name": "Vite"
      },
      {
        "class": "devicon-javascript-plain",
        "name": "Javascript"
      },
      {
        "class": "devicon-typescript-plain",
        "name": "Typescript"
      },
      {
        "class": "devicon-react-original",
        "name": "HTML"
      },
      {
        "class": "devicon-react-original",
        "name": "CSS"
      },
      {
        "class": "devicon-react-original",
        "name": "Chakra-Ui"
      },
      {
        "class": "devicon-react-original",
        "name": "AntD"
      },
      {
        "class": "devicon-react-original",
        "name": "Figma"
      },
      {
        "class": "devicon-react-original",
        "name": "Redux-Toolkit"
      },
      {
        "class": "devicon-javascript-plain",
        "name": "ES6"
      },
      {
        "class": "devicon-express-original",
        "name": "ExpressJs"
      },
      {
        "class": "devicon-mongodb-plain",
        "name": "MongoDb"
      },
      {
        "class": "devicon-mongodb-plain",
        "name": "React-Query"
      },
      {
        "class": "devicon-mongodb-plain",
        "name": "React-Forms"
      },
    ]
  },
  {
    "title": "GuardianVets",
    "startDate": "2022",
    "description": "Guardianvets is an after hour pet care platform where you can get emergency services on call, book an appointment or chat with our customer service rep or call directly for emergency situations. The project had the database that was 719GB in size. Handling the data was a problem in itself.", 
    "role":" I created materialized views to show data to the user in near-realtime. To achieve that I used agendaJs to create ETL pipelines to extract, transform and load the data. In addition to that I used aggregation pipelines to calculate statistics data and send it to the user. I also used the pipelines to create reports in excel format. The dashboard had different kind of data, total appointments, total calls, total chats, convergence rate, ER referrals etc. I also setup read replicas for MySql database that was hosted on RDS service of AWS.",
    "images": [
      gv1,
      gv2
    ],
    "url": "https://analytics-v3.guardianvets.com/sign-in",
    "technologies": [
      {
        "class": "devicon-react-original",
        "name": "React"
      },
      {
        "class": "devicon-javascript-plain",
        "name": "Javascript"
      },
      {
        "class": "devicon-typescript-plain",
        "name": "Typescript"
      },
      {
        "class": "devicon-react-original",
        "name": "Bootstrap"
      },
      {
        "class": "devicon-react-original",
        "name": "HTML"
      },
      {
        "class": "devicon-react-original",
        "name": "CSS"
      },
      {
        "class": "devicon-react-original",
        "name": "React-redux"
      },
      {
        "class": "devicon-javascript-plain",
        "name": "ES6"
      },
      {
        "class": "devicon-express-original",
        "name": "ExpressJs"
      },
      {
        "class": "devicon-mongodb-plain",
        "name": "MongoDb"
      },
      {
        "class": "devicon-amazonwebservices-original",
        "name": "AWS"
      }
    ]
  },
  {
    "title": "ZAP",
    "startDate": "2022",
    "description": "This an e-commerce dashboard. The prime focus in this project was on code optimization and reduction of load times along with development of new features. The project was developed using Javascript, but owing to the complexity of the code, we decided to develop new features with Typescript. Apart from typescript it also uses SP-APIs and google cloud services.",
    "role":"I was responsible for the reconciliation of data through sales reports, ad reports and traffic reports. I was also responsible for optimization of endpoints which decreased the load time by the factor of 10x. I developed the feature of viewing graphs for parent and child products. I used highchartsJs for this purpose. The user can select the KPIs that it wants to view. The user can also view the graph using different timeframes e.g weekly, monthly, yearly etc. I also introduced 3 different y-axis for KPIs that use different unit for measurement.",
    "images": [
      zap1,
      zap2,
      zap3,
      zap4,
    ],
    "url": "https://giftabliss.com/",
    "technologies": [
      {
        "class": "devicon-react-original",
        "name": "React"
      },
      {
        "class": "devicon-javascript-plain",
        "name": "Javascript"
      },
      {
        "class": "devicon-typescript-plain",
        "name": "Typescript"
      },
      {
        "class": "devicon-react-original",
        "name": "AntD"
      },
      {
        "class": "devicon-react-original",
        "name": "Bootstrap"
      },
      {
        "class": "devicon-react-original",
        "name": "HTML"
      },
      {
        "class": "devicon-react-original",
        "name": "CSS"
      },
      {
        "class": "devicon-react-original",
        "name": "React-redux"
      },
      {
        "class": "devicon-javascript-plain",
        "name": "ES6"
      },
      {
        "class": "devicon-express-original",
        "name": "ExpressJs"
      },
      {
        "class": "devicon-mongodb-plain",
        "name": "MongoDb"
      },
      {
        "class": "devicon-amazonwebservices-original",
        "name": "Google Cloud Services"
      }
    ]
  },
    {
      "title": "Blue Warehouse Discounts",
      "startDate": "2022",
      "description": "This is a mobile app that is developed in React-Native.  The app is e-commerce app where user can reserve and buy items at discounted rates. Previously a user could only reserve the item and user had to go to their warehouse to buy the product. Now we have add the functionality of in-app payments and home delivery. Another feature that was added in the app was pickup module for warehouse workers. The worker will scan the barcode using a hand held device which is connected to the mobile by bluetooth.  The purpose of this feature was to reduce the number of errors made while reading the bin-locations.",
      "role":'The app was developed using React-Native, Javascript, NodeJs. We used Stripe as the payment method. We have integrated Shipstation to handle home deliveries. I was responsible for the complete development of pickup module as well as integration of stripe and shipstation. We also add the feature of downloading the tax reports on the admin panel.',
      "images": [
        bwd1,
        bwd2,
        bwd3,
        bwd4,
        bwd5
      ],
      "url": "https://play.google.com/store/apps/details?id=com.bwdmobile&hl=en&gl=US",
      "technologies": [
        {
          "class": "devicon-react-original",
          "name": "React-Native"
        },
        {
          "class": "devicon-react-original",
          "name": "Redux-toolkit"
        },
        {
          "class": "devicon-javascript-plain",
          "name": "Javascript"
        },
        {
          "class": "devicon-javascript-plain",
          "name": "ES6"
        },
        {
          "class": "devicon-express-original",
          "name": "ExpressJs"
        },
        {
          "class": "devicon-mongodb-plain",
          "name": "MongoDB"
        },
        {
          "class": "devicon-amazonwebservices-original",
          "name": "Amazon Web Services"
        }
      ]
    },
    {
      "title": "Inlection Brands",
      "startDate": "2021",
      "description": "A custom dashboard for Amazon marketplaces. It allows you to connect to your store and sync sale and purchase data with your dashboard. It allows to view all the data yearly and monthly. The dashboard automatically sync data for every month as the month progresses. It does that through Agenda Jobs.",
      "role":"I was responsible for performing the complex tasks as well as checking and merging others tasks that were relatively simple. I had 3 junior developers working with me. I was also responsible for writing agenda jobs to fetch the data from Amazon using SP-API and transform it in the intended format. 1)Used React, Javascript, Material Ui for frontend development. 2)Used redux-toolkit for application state management. 3)Used NodeJs and ExpressJs for backend development. 4)Used AgendaJjs to schedule jobs. 5)Used amazon Selling Partner APIs (SP-API) for retrieving historical and individual product data. 6)Configured Jobs for synchronizing the product, order and cost data. ",
      "images": [
        inflection1,
        inflection2,
        inflection3
      ],
      "url": "https://app.inflectionbrands.com/",
      "technologies": [
        {
          "class": "devicon-react-original",
          "name": "React"
        },
        {
          "class": "devicon-react-original",
          "name": "Redux-toolkit"
        },
        {
          "class": "devicon-javascript-plain",
          "name": "Javascript"
        },
        {
          "class": "devicon-javascript-plain",
          "name": "ES6"
        },
        {
          "class": "devicon-express-original",
          "name": "ExpressJs"
        },
        {
          "class": "devicon-mongodb-plain",
          "name": "MongoDB"
        },
        {
          "class": "devicon-mongodb-plain",
          "name": "AgendaJs"
        },
        {
          "class": "devicon-javascript-plain",
          "name": "SP-API"
        },
        {
          "class": "devicon-amazonwebservices-original",
          "name": "Amazon Web Services"
        }
      ]
    },
    {
      "title": "Pricerbot.ai",
      "startDate": "2021",
      "description": "A custom dashboard for Amazon marketplaces. It allows you to connect to your store and sync sale and purchase data with your dashboard. It allows to view all the data yearly and monthly. The dashboard automatically sync data for every month as the month progresses. It does that through Agenda Jobs.",
      "role":"I was responsible for performing the complex tasks as well as checking and merging others tasks that were relatively simple. I had 3 junior developers working with me. I was also responsible for writing agenda jobs to fetch the data from Amazon using SP-API and transform it in the intended format. 1)Used React, Javascript, Material Ui for frontend development. 2)Used redux-toolkit for application state management. 3)Used NodeJs and ExpressJs for backend development. 4)Used AgendaJjs to schedule jobs. 5)Used amazon Selling Partner APIs (SP-API) for retrieving historical and individual product data. 6)Configured Jobs for synchronizing the product, order and cost data. ",
      "images": [
        pricerbot1,
        pricerbot2,
        pricerbot3,
        pricerbot4
      ],
      "url": "https://app.inflectionbrands.com/",
      "technologies": [
        {
          "class": "devicon-react-original",
          "name": "React"
        },
        {
          "class": "devicon-react-original",
          "name": "Redux-toolkit"
        },
        {
          "class": "devicon-javascript-plain",
          "name": "Javascript"
        },
        {
          "class": "devicon-javascript-plain",
          "name": "ES6"
        },
        {
          "class": "devicon-express-original",
          "name": "ExpressJs"
        },
        {
          "class": "devicon-mongodb-plain",
          "name": "MongoDB"
        },
        {
          "class": "devicon-mongodb-plain",
          "name": "AgendaJs"
        },
        {
          "class": "devicon-javascript-plain",
          "name": "SP-API"
        },
        {
          "class": "devicon-amazonwebservices-original",
          "name": "Amazon Web Services"
        }
      ]
    },
    {
      "title": "My African Events",
      "startDate": "2020",
      "description": "My African Events is a social connectivity and events platform for African American community. Anyone can register as an event organizer and host events. The tickets to the events can be paid or free. The organizer can also accept donations. The user can view all the events and buy tickets for those events and pay through an integrated payment system which was achieved through stripe. The project was developed using React, Redux, Javascript, Bootstrap, CSS, ExpressJs, PostgreSQL and AWS",
      "role":"I was responsible for developing multiple screens i.e donations page, Home Page, Login page, Signup page, discover-events page etc on the client side and I was also responsible for stripe integration on the client side.",
      "images": [
        mae1,
        mae2,
        mae3,
        mae4
      ],
      "url": "https://myafricanevents.com/",
      "technologies": [
        {
          "class": "devicon-react-original",
          "name": "React"
        },
        {
          "class": "devicon-react-original",
          "name": "Bootstrap"
        },
        {
          "class": "devicon-react-original",
          "name": "HTML"
        },
        {
          "class": "devicon-react-original",
          "name": "SCSS"
        },
        {
          "class": "devicon-react-original",
          "name": "React-redux"
        },
        {
          "class": "devicon-javascript-plain",
          "name": "Javascript"
        },
        {
          "class": "devicon-javascript-plain",
          "name": "ES6"
        },
        {
          "class": "devicon-express-original",
          "name": "ExpressJs"
        },
        {
          "class": "devicon-mongodb-plain",
          "name": "PostgresSQL"
        },
        {
          "class": "devicon-amazonwebservices-original",
          "name": "Amazon Web Services"
        },
        {
          "class": "devicon-amazonwebservices-original",
          "name": "Stripe"
        }
      ]
    },
    {
      "title": "Giftabliss",
      "startDate": "2020",
      "description": "Technical skills in use are Node.js, React, CSS, Redux, AWS, Material UI, JavaScript, HTML, Bootstrap, Cards & Payments, and E-Commerce. GIFTABLISS was a custom eCommerce solution developed using CSS, AWS, Node.js, etc. There were 4 parts to this project. The desktop site, CRM panel, shipper panel, and the Mobile site that was specifically designed for mobile users. The contents of the customer site were entirely configurable from the CRM panel. Completely developed the shipper panel which had features like e-signatures, Email Notifications, etc. and most of the modules of the CRM panel. Also integrated the payment methods (Paypal and Payfort). I was also responsible for developing the Apis for all these panels for data provision. Apart from that also integrated the Email Notification System.",
      "role":"Paypal and Payfort were integrated as payment methods. All the CRM panels were also developed using the same technologies. I was the lead developer in all of these modules.",
      "images": [
        gab1,
        gab2,
        gab3,
        gab4,
        gab5,
        gab6,
      ],
      "url": "https://giftabliss.com/",
      "technologies": [
        {
          "class": "devicon-react-original",
          "name": "React"
        },
        {
          "class": "devicon-react-original",
          "name": "Meterial UI"
        },
        {
          "class": "devicon-react-original",
          "name": "Bootstrap"
        },
        {
          "class": "devicon-react-original",
          "name": "HTML"
        },
        {
          "class": "devicon-react-original",
          "name": "CSS"
        },
        {
          "class": "devicon-react-original",
          "name": "React-redux"
        },
        {
          "class": "devicon-javascript-plain",
          "name": "Javascript"
        },
        {
          "class": "devicon-javascript-plain",
          "name": "ES6"
        },
        {
          "class": "devicon-express-original",
          "name": "ExpressJs"
        },
        {
          "class": "devicon-mongodb-plain",
          "name": "Sequelize"
        },
        {
          "class": "devicon-amazonwebservices-original",
          "name": "Amazon Web Services"
        }
      ]
    }
];

export { projects };
